import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import keycloak from './Keycloak';

function App() {
    const [activeTab, setActiveTab] = useState('app1');
    const [input1, setInput1] = useState('');
    const [results1, setResults1] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [progress1, setProgress1] = useState(0);

    const [productCodes, setProductCodes] = useState('');
    const [results2, setResults2] = useState([]);
    const [loading2, setLoading2] = useState(false);
    const [progress2, setProgress2] = useState(0);

    const [categoryPxl, setCategoryPxl] = useState('');
    const [results3, setResults3] = useState([]);
    const [loading3, setLoading3] = useState(false);

    const [input4, setInput4] = useState('');
    const [loading4, setLoading4] = useState(false);
    const [progress4, setProgress4] = useState(0);
    const [results4, setResults4] = useState([]);

    // Handle submit for App 1
    const handleApp1Submit = async () => {
        const lines = input1.split('\n').filter((line) => line.trim() !== '');
        setLoading1(true);
        setProgress1(0);
        setResults1([]);

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const [supplier, supplierKode] = line.split(',');
            if (supplier && supplierKode) {
                try {
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + keycloak.token, // Replace with your token
                    };
                    const response = await axios.post('api/fetch-data', {
                        supplier: supplier.trim(),
                        supplierKode: supplierKode.trim(),
                    }, { headers });
                    setResults1(prev => [...prev, { supplier, supplierKode, status: response.data.status }]);
                } catch (error) {
                    setResults1(prev => [...prev, { supplier, supplierKode, status: 'Error' }]);
                }
            }
            setProgress1(((i + 1) / lines.length) * 100);
        }
        setLoading1(false);
    };// Handle submit for App 1

    const processCategory = async () => {
        const lines = input4.split('\n').filter((line) => line.trim() !== '');
        setLoading4(true);
        setProgress4(0);
        setResults4([]);

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const [categoryCode, keywords, locale] = line.split(',');
            if (categoryCode && keywords && locale) {
                try {
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + keycloak.token, // Replace with your token
                    };
                    const response = await axios.post('api/processCategory', {
                        categoryCode: categoryCode.trim(),
                        keyWords: keywords.trim(),
                        locale: locale.trim()
                    }, { headers });
                    setResults4(prev => [...prev, { categoryCode, keywords, score: response.data.score, url: response.data.url }]);
                } catch (error) {
                    setResults4(prev => [...prev, { categoryCode, keywords, status: 'Error' }]);
                }
            }
            setProgress4(((i + 1) / lines.length) * 100);
        }
        setLoading4(false);
    };

    // Handle submit for App 2
    const optimizeProduct = async () => {
        const codesArray = productCodes.split('\n').filter((code) => code.trim() !== '');
        setLoading2(true);
        setProgress2(0);
        setResults2([]); // Clear previous results

        for (let i = 0; i < codesArray.length; i++) {
            const code = codesArray[i];
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + keycloak.token, // Replace with your token
                };
                const response = await axios.post('process', { productCodes: [code] }, { headers });
                const newResult = response.data[0]; // Assuming server returns an array with a single result

                setResults2((prevResults) => [...prevResults, newResult]);
                setProgress2(((i + 1) / codesArray.length) * 100);
            } catch (error) {
                console.error('Error processing product code:', code, error);
            }
        }

        setLoading2(false);
    };

    // Handle submit for App 3 (New Functionality)
    const handleApp3Submit = async () => {
        setLoading3(true);
        setResults3([]);

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + keycloak.token, // Replace with your token
            };
            const response = await axios.post('api/copy-products-from-category', {
                categoryPxl: categoryPxl.trim(),
            }, { headers });
            setResults3(response.data);
        } catch (error) {
            console.error('Error processing category:', categoryPxl, error);
        } finally {
            setLoading3(false);
        }
    };

    // Function to handle copying the results to clipboard
    const handleCopyResults = () => {
        let copyText = 'ZAP Code\tProduct Code\tPosition\tSupplier\tSupplier Code\tZAP Code + Position\n';

        results3.forEach(result => {
            copyText += `${result.zap_code || 'N/A'}\t${result.code}\t${result.pos}\t${result.supplier}\t${result.supplier_code}\t${result.zap_code || 'N/A'},${result.pos}\n`;
        });

        navigator.clipboard.writeText(copyText).then(() => {
            alert('Results copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <div className="App container mt-5">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'app1' ? 'active' : ''}`}
                        onClick={() => setActiveTab('app1')}
                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                        Add Pim Products in Webshop
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'app2' ? 'active' : ''}`}
                        onClick={() => setActiveTab('app2')}
                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                        Optimize Products
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'app3' ? 'active' : ''}`}
                        onClick={() => setActiveTab('app3')}
                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                        Copy Products from Category
                    </button>
                </li>
                <li className="nav-item" class="p-l-15">
                    <button
                        className={`nav-link ${activeTab === 'category' ? 'active' : ''}`}
                        onClick={() => setActiveTab('category')}
                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    >
                        Compute category description
                    </button>
                </li>
            </ul>

            {activeTab === 'app1' && (
                <div className="mt-4">
                    <h2>Add Supplier Pim Products in Webshop</h2>
                    <p>Will only add them if the product doesn't exist in webshop</p>
                    <textarea
                        className="form-control"
                        rows="10"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Paste suppliers and supplier codes here, separated by commas. Example: PFCONCEPT-IMPORT,102222"
                    />
                    <div className="text-center mb-4 mt-4">
                        <button className="btn btn-primary" onClick={handleApp1Submit} disabled={loading1}>
                            {loading1 ? 'Processing...' : 'Submit'}
                        </button>
                    </div>
                    {loading1 && (
                        <div className="progress mb-4">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{ width: `${progress1}%` }}
                            >
                                {progress1.toFixed(0)}%
                            </div>
                        </div>
                    )}
                    <h3 className="text-center mb-4">Results</h3>
                    {results1.length > 0 ? (
                        <ul>
                            {results1.map((result, index) => (
                                <li key={index}>
                                    {result.supplier} - {result.supplierKode}: {result.status}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-center">No results to display. Please enter suppliers and supplier codes and click "Submit".</p>
                    )}
                </div>
            )}

            {activeTab === 'app2' && (
                <div className="mt-4">
                    <h1 className="text-center mb-4">Optimize Products</h1>
                    <h5 className="text-center mb-1">Translate, set main category and activate products</h5>
                    <textarea
                        className="form-control"
                        rows="10"
                        value={productCodes}
                        onChange={(e) => setProductCodes(e.target.value)}
                        placeholder="Paste product codes here, one per line"
                    />
                    <div className="text-center mb-4 mt-4">
                        <button className="btn btn-primary" onClick={optimizeProduct} disabled={loading2}>
                            {loading2 ? 'Processing...' : 'Process Codes'}
                        </button>
                    </div>
                    {loading2 && (
                        <div className="progress mb-4">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{ width: `${progress2}%` }}
                            >
                                {progress2.toFixed(0)}%
                            </div>
                        </div>
                    )}
                    <h2 className="text-center mb-4">Results</h2>
                    {results2.length > 0 ? (
                        <table className="table table-striped">
                            <thead className="thead-dark">
                            <tr>
                                <th>Product Code</th>
                                <th>Category Name</th>
                                <th>Category ID</th>
                                <th>Update Status</th>
                                <th>Activation Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {results2.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.productCode}</td>
                                    <td>{result.categoryName}</td>
                                    <td>{result.categoryId}</td>
                                    <td>{result.updateStatus}</td>
                                    <td>{result.updateStatus3}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-center">No results to display. Please enter product codes and click "Process Codes".</p>
                    )}
                </div>
            )}

            {activeTab === 'app3' && (
                <div className="mt-4">
                    <h2>Copy Products from Category</h2>
                    <textarea
                        className="form-control"
                        rows="1"
                        value={categoryPxl}
                        onChange={(e) => setCategoryPxl(e.target.value)}
                        placeholder="Enter the PXL category"
                    />
                    <div className="text-center mb-4 mt-4">
                        <button className="btn btn-primary" onClick={handleApp3Submit} disabled={loading3}>
                            {loading3 ? 'Processing...' : 'Submit'}
                        </button>
                    </div>
                    {loading3 && (
                        <div className="progress mb-4">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{ width: '100%' }}
                            >
                                Processing...
                            </div>
                        </div>
                    )}
                    <h3 className="text-center mb-4">Results</h3>
                    {results3.length > 0 && (
                        <>
                            <button className="btn btn-secondary mb-4" onClick={handleCopyResults}>
                                Copy Results to Clipboard
                            </button>
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>ZAP Code</th>
                                    <th>Product Code</th>
                                    <th>Position</th>
                                    <th>Supplier</th>
                                    <th>Supplier Code</th>
                                    <th>ZAP Code + Position</th>
                                </tr>
                                </thead>
                                <tbody>
                                {results3.map((result, index) => (
                                    <tr key={index}>
                                        <td>{result.zap_code || 'N/A'}</td>
                                        <td>{result.code}</td>
                                        <td>{result.pos}</td>
                                        <td>{result.supplier}</td>
                                        <td>{result.supplier_code}</td>
                                        <td>{`${result.zap_code || 'N/A'},${result.pos}`}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    {results3.length === 0 && (
                        <p className="text-center">No results to display. Please enter a category and click "Submit".</p>
                    )}
                </div>
            )}

            {activeTab === 'category' && (
                <div className="mt-4">
                    <h2>Process category description</h2>
                    <p>Import category description</p>
                    <textarea
                        className="form-control"
                        rows="10"
                        value={input4}
                        onChange={(e) => setInput4(e.target.value)}
                        placeholder="Paste category code, keywords, locale (en-gb, nl-nl, fr-fr, fr-be, nl-be, de-at, de-de, it-it, es-es, da-dk, etc.)"
                    />
                    <div className="text-center mb-4 mt-4">
                        <button className="btn btn-primary" onClick={processCategory} disabled={loading4}>
                            {loading1 ? 'Processing...' : 'Submit'}
                        </button>
                    </div>
                    {loading4 && (
                        <div className="progress mb-4">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                style={{ width: `${progress4}%` }}
                            >
                                {progress4.toFixed(0)}%
                            </div>
                        </div>
                    )}
                    <h3 className="text-center mb-4">Results</h3>
                    {results4.length > 0 ? (
                        <ul>
                            {results4.map((result, index) => (
                                <li key={index}>
                                    {result.categoryCode} - {result.keywords}: {result.score} {result.url}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-center">No results to display. data and click "Submit".</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default App;
